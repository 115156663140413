<template>
  <AppHeader />
  <br>
    <Suspense>
      <template #default>
        <GameDetails :id="id"/>
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import { defineAsyncComponent, Suspense } from "vue";
const GameDetails = defineAsyncComponent(() =>
  import('@/components/Pages/GameDetails.vue')
)
export default {
  props: {
    id: String
  },
  name: "GameDetail",
  components: {
    AppHeader,
    AppFooter,
    GameDetails,
    Suspense,
    Spinner
  },
};
</script>

<style>
</style>